import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import hawkeye from "../images/hawkeye-logo.png"

const ReportsAndDashboards = () => (
  <Layout>
    <SEO title="Reports and Dashboards" />
    <div className="page-content">
      <div className="container">
        <h1 className="text-orange">Reports and Dashboards</h1>
        <div className="row">
          <div className="col-md-8">
            <p>
              Organize multiple databases and spreadsheets in easy-to-understand
              dashboards. Summarize information in a meaningful and impactful
              way. View graphs, charts, tables, and visualizations for your data
              without trouble. Generate reports and share them with the whole
              team to improve operational efficiency and communication.
            </p>
            <p>
              These reports and dashboards are highly useful during board
              meetings and investor talks as well. Wow potential customers and
              business partners with your ability to provide succinct data from
              a bunch of sources without getting overwhelmed.
            </p>
            <p>
              Promote understanding of crucial information among the ranks of
              your company. Cut back on the hassle and delays. Take full
              advantage of machine learning and artificial intelligence for
              optimum efficiency.
            </p>
            <p>
              For a comprehensive list of our reports and dashboards solutions,
              email us at
              <a
                href="mailto:info@grayhawkland.com"
                className="text-orange mx-1"
              >
                info@grayhawkland.com
              </a>
              or call us at
              <a href="tel:303.223.1665" className="text-orange mx-1">
                303.223.1665
              </a>
            </p>
          </div>
          <div className="col-lg-4">
            <img src={hawkeye} className="img-fluid" />
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default ReportsAndDashboards
